<template>
  <section id="list-avaliable-hours">
    <div class="row time-filter">
      <div class="col-12 multi-padding">
        <multi-select
          placeholder="Profissional"
          v-model="tempProfessionalValue"
          :options="professionals"
          :searchable="true"
        />
      </div>
    </div>
    <div id="infinite-list">
      <div
        class="content row fc-content fc-day-content event-scheduled"
        v-for="(data, dataIndex) in this.data"
        :key="dataIndex"
      >
        <div class="td-30 col-12">
          <div class="content blue-back padding-fix">
            <div class="blue">
              {{
                data?.person?.gender === "M"
                  ? "Dr."
                  : data?.person?.gender === "F"
                  ? "Dra."
                  : ""
              }}
              {{ data?.person?.name }}
            </div>
            <div class="light margin-fix">
              Total: {{ data.total }} | Disponível: {{ data.available }} | Agendado:
              {{ data.scheduled }} | Encaixe: {{ data.schedule_fitting }}
            </div>
          </div>
          <div class="content padding-fix">
            <span
              class="time-unselected pointer"
              v-for="(item, itemIndex) in data.slots"
              :key="itemIndex"
              @click="
                openAppointmentModal(
                  item.start_datetime.slice(-8, -3),
                  item.end_datetime.slice(-8, -3),
                  data.person
                )
              "
            >
              {{ moment(item.start_datetime).format("HH:mm") }}
            </span>
          </div>
        </div>
      </div>
      <div v-show="this.loadingIcon" id="loadingIcon">
        <Loading />
      </div>
    </div>

    <AppointmentModal
      :professionalId="professionalObject"
      :date="selectedDate"
      :setAppointmentId="setAppointmentId"
      :id="idModal"
    />
  </section>
</template>
<script>
import { getCurrentClinic } from "@/utils/localStorageManager";
import moment from "moment";

export default {
  name: "ListAvaliableHours",
  components: {
    AppointmentModal: () => import("@/components/AppointmentModal"),
    Loading: () => import("@/assets/icons/loading-dual-transparent.svg"),
    MultiSelect: () => import("@/modules/schedule/components/MultiSelect"),
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    date: Date,
  },
  data() {
    return {
      idModal: 'sidebar-avaliable-hours',
      clinic: getCurrentClinic(),
      total: 0,
      scheduled: 0,
      scheduleFitting: 0,
      available: 0,
      selectedStartTime: null,
      selectedEndTime: null,
      selectedDate: null,
      loadingIcon: true,
      professionalObject: null,
      professionals: [],
      data: [],
      tempProfessionalValue: [],
      selectedProfessionals: [],
      appointmentId: null,
    };
  },
  watch: {
    tempProfessionalValue: {
      handler: function (val) {
        this.selectedProfessionals = val.map((item) => item.value);

        this.getData();
      },
    },
  },
  mounted() {
    this.getDoctors();
    this.getData();

    const masonry = document.querySelector("#infinite-list");
    masonry.addEventListener("scroll", (e) => {
      if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
        this.loadMorePages(this.nextPage);
      }
    });
  },
  methods: {
    setAppointmentId(id) {
      this.appointmentId = id;
    },
    moment,
    getDoctors() {
      this.api
        .getDoctors(this.clinic.id)
        .then(({ data }) => {
          this.professionals = data.doctors.map((doctor) => ({
            value: doctor.id,
            label: doctor.name,
            picture: doctor.picture,
            el: doctor,
          }));
        })
        .catch(console.log);
    },
    getData() {
      this.loadingIcon = true;

      const parsedDate = new Date(this.date).toISOString();

      try {
        this.api
          .getAvailableSlots(this.clinic.id, this.selectedProfessionals, parsedDate)
          .then((res) => {
            this.data = res.data;
            this.loadingIcon = false;
          })
          .catch((err) => {
            console.log("Err", err);
          });
      } catch (err) {
        console.log(err);
      }
    },
    async openAppointmentModal(startTime, endTime, docObj) {
      this.selectedStartTime = startTime;
      this.selectedEndTime = endTime;
      this.professionalObject = docObj;
      this.selectedDate = this.moment(this.date)
        .set({
          hour: this.selectedStartTime.slice(0, 2),
          minute: this.selectedStartTime.slice(-2),
        })
        .toDate();

      this.$bvModal.show(this.idModal);
    },
  },
};
</script>
<style lang="scss">
#list-avaliable-hours {
  gap: 16px;
  #loadingIcon {
    margin-top: 20px;
  }
  .form-group {
    margin-bottom: 0 !important;
  }
  .multiselect {
    margin-bottom: 0;
    .multiselect__tags {
      width: 100%;
      height: 38px;
      border: 1px solid var(--neutral-300);
    }
    .multiselect__placeholder {
      margin-right: 1rem;
    }
  }
  .avatar-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .avatar {
    display: flex;
    align-items: center;
    margin: 0 3px 0 0 !important;
  }
  .multi-padding {
    padding: 4px 28px;
  }
  .blue-back {
    border-radius: 8px;
    background: var(--Blue-100, #e7ecfe) !important;
    padding: 4px 16px !important;
    margin-bottom: 8px;
  }
  .margin-fix {
    margin-bottom: 8px;
  }
  .pointer {
    cursor: pointer;
  }
  .time-unselected {
    display: inline-flex;
    padding: 4px 12px;
    margin: 4px;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    border: 1px dashed var(--Blue-500, #305bf2);
    background: var(--Neutral-white, #fff);
    color: var(--Blue-500, #305bf2);

    /* Text / Extra Small */
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    transition: background, color 200ms;
  }
  .time-unselected:hover {
    //border: 1px var(--Blue-500, #305BF2);
    background: var(--Neutral-white, #305bf2);
    color: var(--Blue-500, #fff);
  }
  .content {
    background: white;
    border-radius: 8px;
    padding: 16px 25px;
    border: 1px;
    .blue {
      color: var(--Blue-500, #305bf2);
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 21px */
    }
    .light {
      color: #525c7a;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
  .padding-fix {
    padding: 0px 16px;
  }
}
</style>
